import * as Sentry from '@sentry/browser';

Sentry.init({
	dsn: 'https://4360c18ee94d416b9c24df9ce1d27b81@sentry.buildit-consulting.com/4',

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	integrations: [
		new Sentry.Replay({
			// Additional SDK configuration goes in here, for example:
			maskAllText: true,
			blockAllMedia: true,
		}),
	],
});
